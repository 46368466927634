<template>
  <c-grid
    v-dragscroll
    position="relative"
    width="100%"
    flex-grow="1"
    align-items="center"
    justify-content="flex-start"
    overflow="auto"
    :template-columns="customTemplateColumns"
  >
    <c-flex
      v-for="i in listDays"
      :key="i"
      :width="customItemWidth"
      :min-width="customItemMinWidth"
      justify-content="center"
    >
      <c-flex
        flex-direction="column"
        justify-content="center"
        :width="['25px', '48px']"
        :height="['43px', '76px']"
        text-align="center"
        border-radius="30px"
        :background-color="i == selectedDay ? 'primary.400' : (checkedDays.includes(i) || daysState[i] === 'haha' ? 'primary.50' : 'transparent')"
        :padding="['1px','4px']"
        :cursor="disabledDay.includes(i) ? 'not-allowed' : 'pointer'"
        :_hover="{
          backgroundColor: disabledDay.includes(i) ? '' : (i == selectedDay ? 'primary.400' : daysState[i] === 'haha' ? 'primary.100' : 'neutral.superLightGray'),
        }"
        @click="onChangeDay(i)"
      >
        <c-text
          :font-size="['10px','16px']"
          :color="i == selectedDay ? 'neutral.white' : 'neutral.888888'"
          position="relative"
        >
          Hari
          <c-box
            v-if="dotBadgeDays.includes(i) || (daysState[i] === 'haha' && i != selectedDay)"
            position="absolute"
            :width="['3px','8px']"
            :height="['3px','8px']"
            border-radius="8px"
            top="0px"
            right="-5px"
            background-color="#F4CC46"
            class="animate-pulse"
          />
          <c-box
            v-if="(checkedDays.includes(i) && (i != selectedDay)) || (daysState[i] === 'haha' && i != selectedDay)"
            position="absolute"
            top="0"
            right="-8px"
            width="12px"
            height="12px"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-circle-check.svg')"
              width="100%"
              height="100%"
              fill="#008C81"
            />
          </c-box>
        </c-text>
        <c-flex
          position="relative"
          justify-content="center"
          align-items="center"
          :width="['20px','40px']"
          :height="['20px','40px']"
          :border-radius="['20px', '40px']"
          :background-color="i == selectedDay ? 'neutral.white' : 'transparent'"
          :color="i == selectedDay ? 'primary.400' : 'neutral.black'"
          :font-size="['14px','20px']"
          font-weight="600"
          margin="auto"
        >
          {{ i }}
          <c-box
            v-if="isIncludeInvalidDayValidation(i)"
            position="absolute"
            :width="['3px','8px']"
            :height="['3px','8px']"
            border-radius="8px"
            :top="['3px','6px']"
            :right="['3px','6px']"
            background-color="#FF0000"
            class="animate-pulse"
          />
        </c-flex>
      </c-flex>
    </c-flex>
  </c-grid>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'ChipDays',
  directives: {
    dragscroll,
  },
  props: {
    startAt: {
      type: Number,
      default: 1,
    },
    endAt: {
      type: Number,
      default: 7,
    },
    selectedDay: {
      type: [String, Number],
      default: 1,
    },
    invalidDayValidation: {
      type: Array,
      default: () => [],
    },
    disabledDay: {
      type: Array,
      default: () => [],
    },
    customItemMinWidth: {
      type: String,
      default: '128px',
    },
    customItemWidth: {
      type: String,
      default: '100%',
    },
    customTemplateColumns: {
      type: String,
      default: 'repeat(7, 1fr)',
    },
    daysState: {
      type: Object,
      default: () => ({}),
    },
    checkedDays: {
      type: Array,
      default: () => [],
    },
    dotBadgeDays: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['on-change-day'],
  computed: {
    listDays() {
      if (this.startAt < this.endAt) {
        const numbers = []
        for (let i = this.startAt; i <= this.endAt; i++) {
          numbers.push(i)
        }
        return numbers
      }
      return []
    },
  },
  methods: {
    isIncludeInvalidDayValidation(day) {
      return this.invalidDayValidation.includes(day)
    },
    onChangeDay(day) {
      if (this.disabledDay.includes(day)) return
      if (this.selectedDay != day) {
        this.$emit('on-change-day', day)
      }
    },
  },
}
</script>

<style scoped>
</style>
